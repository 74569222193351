import { Patient } from '@models/patient';
import { Avatar, Button, Flex, Row, Typography, Modal } from 'antd';
import { getFirstChar } from '../../utils/string';
import {
  FileTextOutlined,
  IdcardOutlined,
  LineChartOutlined,
  MonitorOutlined,
} from '@ant-design/icons';
import { PatientCareLevel } from '../../constants/careLevel';
import useMobile from '../../hooks/useMobile';
import { IndependentLifeLevel } from '../../constants/independentLifeLevel';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export type Props = {
  patient: Patient;
  ohatScoresCount: number;
  onClick: () => {};
};
export const PatientDetailPanel = (props: Props) => {
  const { patient, ohatScoresCount, onClick } = props;
  const { isMobile } = useMobile();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const lifeLevel = IndependentLifeLevel.getByKey(patient.independentLifeLevel);
  const careLevel = PatientCareLevel.getByKey(patient.careLevel);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onClickEdit = () => {
    navigate(`edit`);
  };
  return (
    <Flex
      vertical
      align="center"
      gap={isMobile() ? 'small' : 'middle'}
      justify="start"
    >
      <Avatar size={{ xs: 60, sm: 60, md: 80, lg: 80, xl: 80, xxl: 80 }}>
        {getFirstChar(patient.name ?? '')}
      </Avatar>
      <Typography.Text style={{ fontSize: 20, fontWeight: 'bold' }}>
        {patient.age ? `${patient.name} (${patient.age}歳)` : `${patient.name}`}
      </Typography.Text>
      <Flex vertical align="center" style={{ width: '100%' }}>
        <Row style={{ color: 'gray' }}>
          <LineChartOutlined />
          <Typography.Text style={{ marginLeft: 8 }}>
            {`OHAT評価回数 ${ohatScoresCount}回`}
          </Typography.Text>
        </Row>
        <Row style={{ color: 'gray' }}>
          <IdcardOutlined />
          <Typography.Text style={{ marginLeft: 8 }}>
            {`${
              lifeLevel === IndependentLifeLevel.none
                ? '日常生活自立度 なし'
                : lifeLevel.label
            } ${
              careLevel === PatientCareLevel.none
                ? '介護度 なし'
                : careLevel.label
            } `}
          </Typography.Text>
        </Row>
        {patient.memo && (
          <>
            <Row style={{ color: 'gray' }}>
              <FileTextOutlined />
              <Typography.Text style={{ marginLeft: 8 }}>
                患者メモ
              </Typography.Text>
            </Row>
            <Row style={{ color: 'gray', width: '100%', marginTop: 3 }}>
              <Flex
                flex={1}
                vertical
                style={{
                  width: '100%',
                  backgroundColor: '#f5f5f5',
                  padding: 6,
                  borderRadius: 8,
                  border: '1px solid #d9d9d9',
                }}
              >
                <Typography.Paragraph
                  ellipsis={{ rows: 3 }}
                  style={{
                    width: '100%',
                    lineBreak: 'auto',
                    marginBottom: 0,
                    fontSize: 12,
                  }}
                >
                  {patient.memo}
                </Typography.Paragraph>
                <Button
                  type="link"
                  onClick={showModal}
                  style={{ marginTop: 0, fontSize: 12, paddingBottom: 0 }}
                >
                  もっと見る
                </Button>
              </Flex>
            </Row>
          </>
        )}
      </Flex>
      <Button shape="round" type="default" onClick={onClickEdit}>
        {`患者メモ${patient.memo ? '編集' : '追加'}`}
      </Button>
      <Button
        shape="round"
        size="large"
        type="primary"
        onClick={onClick}
        icon={<MonitorOutlined />}
      >
        OHAT評価
      </Button>

      <Modal
        title="患者メモ"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <Typography.Paragraph
          style={{
            whiteSpace: 'pre-wrap',
            height: '60vh',
            overflowY: 'auto',
          }}
        >
          {patient.memo}
        </Typography.Paragraph>
      </Modal>
    </Flex>
  );
};
