import { Form, Input, Button, Radio, InputNumber } from 'antd';
import 'dayjs/locale/ja';
import {
  CreatePatientDto,
  Patient,
  UpdatePatientDto,
} from '../../../../models';
import { formatYYYYMMDD } from '../../../../utils/date';
import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { getBaseConstantsOptions } from '../../../../utils/option-utils';
import { IndependentLifeLevel } from '../../../../constants/independentLifeLevel';
import { PatientCareLevel } from '../../../../constants/careLevel';
import useLoading from '../../../../hooks/useLoading';
import { DentalIntervention } from '../../../../constants/dentalIntervention';

export type Props = {
  onSubmit: (values: CreatePatientDto | UpdatePatientDto) => void;
  initialValue?: Patient;
  onCancel?: () => void;
  failedCallback?: (info: any) => void;
};
export const PatientForm = (props: Props) => {
  const { initialValue, onSubmit, onCancel, failedCallback = () => {} } = props;
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [form] = Form.useForm();

  const lifeLevelOptions = useMemo(
    () => getBaseConstantsOptions(IndependentLifeLevel.getAll()),
    [],
  );
  const careLevelOptions = useMemo(
    () => getBaseConstantsOptions(PatientCareLevel.getAll()),
    [],
  );
  const dentalInterventionOptions = useMemo(
    () => getBaseConstantsOptions(DentalIntervention.getAll()),
    [],
  );

  useEffect(() => {
    if (initialValue) {
      form.setFieldsValue({
        name: initialValue.name,
        age: initialValue.age,
        birthDate: dayjs(initialValue.birthDate),
        gender: initialValue.gender,
        careLevel: initialValue.careLevel,
        independentLifeLevel: initialValue.independentLifeLevel,
        dentalIntervention: initialValue.dentalIntervention,
        memo: initialValue.memo,
      });
    }
  }, [form, initialValue]);

  const onFinish = async (values: CreatePatientDto) => {
    values.birthDate = formatYYYYMMDD(values.birthDate);
    startLoading();
    await onSubmit(values);
    stopLoading();
  };

  const onFinishFailed = (errorInfo: any) => {
    failedCallback(errorInfo);
  };

  return (
    <Form
      form={form}
      name="patientForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      style={{ width: '100%' }}
      layout="vertical"
    >
      <Form.Item
        label="氏名"
        name="name"
        rules={[{ required: true, message: '必須入力です' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="年齢" name="age">
        <InputNumber min={0} max={150} />
      </Form.Item>

      {/* <Form.Item
        label="生年月日"
        name="birthDate"
        initialValue={getDefaultPatientBirthYear()}
        rules={[{ required: true, message: '必須入力です' }]}
      >
        <DatePicker locale={locale} />
      </Form.Item> */}

      <Form.Item
        label="性別"
        name="gender"
        initialValue={'male'}
        rules={[{ required: true, message: '必須入力です' }]}
      >
        <Radio.Group>
          <Radio.Button value="male">男性</Radio.Button>
          <Radio.Button value="female">女性</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="介護度"
        name="careLevel"
        initialValue={PatientCareLevel.none.key}
        rules={[{ required: true, message: '必須入力です' }]}
      >
        <Radio.Group>
          {careLevelOptions.map((opt) => (
            <Radio key={opt.key} value={opt.key}>
              {opt.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="日常生活自立度"
        name="independentLifeLevel"
        initialValue={IndependentLifeLevel.none.key}
        rules={[{ required: true, message: '必須入力です' }]}
      >
        <Radio.Group>
          {lifeLevelOptions.map((opt) => (
            <Radio key={opt.key} value={opt.key}>
              {opt.label}
            </Radio>
          ))}
        </Radio.Group>

        {/* <Select
          showSearch
          placeholder="日常生活自立度を選択"
          optionFilterProp="children"
        >
          {lifeLevelOptions.map((opt) => (
            <Select.Option key={opt.key} value={opt.key}>
              {opt.label}
            </Select.Option>
          ))}
        </Select> */}
      </Form.Item>

      <Form.Item
        label="歯科介入"
        name="dentalIntervention"
        initialValue={DentalIntervention.none.key}
        rules={[{ required: true, message: '必須入力です' }]}
      >
        <Radio.Group>
          {dentalInterventionOptions.map((opt) => (
            <Radio key={opt.key} value={opt.key}>
              {opt.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="患者メモ"
        name="memo"
        tooltip="2000文字以内で入力してください"
        rules={[{ max: 2000, message: '2000文字以内で入力してください' }]}
      >
        <Input.TextArea rows={4} placeholder="メモを入力" />
      </Form.Item>

      <Form.Item>
        <Button
          block
          type="primary"
          size="large"
          shape="round"
          htmlType="submit"
          loading={isLoading}
        >
          送信
        </Button>
        {onCancel && (
          <Button
            block
            style={{ marginTop: 12 }}
            size="large"
            shape="round"
            onClick={onCancel}
          >
            キャンセル
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};
